/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../Icon';
import Link from '../../link/Link';
import useFormValues from '../../../../hooks/useFormValues';
import { ValidationError } from '../../../../models/error.model';
import IconField from './IconField';
import InputField from './InputField';

interface FieldProps {
  name?: string;
  type?: string;
  desktop?: boolean;
  toggleSearch?: () => void;
  customText?: React.ReactNode;
  mandatory?: boolean;
  id?: string;
  label?: string;
  littleCheck?: boolean;
  className?: string;
  propsInput?: InputProps;
  expandSearch?: () => void;
  onEmptyValue?: () => void;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  selectClass?: string;
  validationRules?: ((data, data2?) => ValidationError)[];
  validateOnBlur?: boolean;
  defaultValue?: string | number | boolean;
  hidden?: boolean;
  placeHolder?: string;
}

interface InputProps {
  onKeyUp?: (e?, valueData?) => void;
  onFocus?: (e?, valueData?) => void;
  value?: string | number | boolean;
  placeholder?: string;
  checked?: boolean;
  disabled?: boolean;
  accept?: string;
  id?: string | number;
  name?: string;
  validationRules?;
  validateOnBlur?: boolean;
  propKey?: string;
  onValidation?: (data) => void;
  trim?: boolean;
  onChange?: (data) => void;
  initialValue?;
  onBlur?: (data) => void;
  onClick?: () => void;
}

interface FieldState {
  focusSearch: boolean;
  internalMessage: string;
  placeholder: string;
  valid: boolean;
  showed: boolean;
}

const Field = ({
  type,
  desktop,
  propsInput,
  toggleSearch,
  customText,
  mandatory,
  id,
  label,
  className: classes,
  expandSearch,
  onEmptyValue,
  littleCheck,
  validationRules,
  validateOnBlur,
  name,
  children,
  selectClass,
  defaultValue,
  placeHolder,
}: FieldProps): JSX.Element => {
  const { setErrorsFormValues, errorForm } = useFormValues();
  const { placeholder, value, onKeyUp } = propsInput || {};
  const [state, setState] = useState<FieldState>({
    focusSearch: false,
    internalMessage: '',
    placeholder,
    showed: false,
    valid: true,
  });

  const inputRef = useRef(null);

  const focusInput = (): void => {
    if (inputRef?.current?.focus) {
      inputRef?.current?.focus();
    }
  };

  const setValue = (valueData: string | number | boolean, noValidate?): void => {
    if (!inputRef?.current?.setValue) return;

    if (valueData === '') {
      setState((prevState) => ({ ...prevState, focusSearch: false }));

      if (onKeyUp) {
        onKeyUp({}, value);
      }
    }

    inputRef.current.setValue(valueData, noValidate);
  };

  useEffect(() => {
    if (type === 'search' && desktop) {
      focusInput();
    }
  }, [desktop, type]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, placeholder }));
  }, [placeholder]);

  useEffect(() => {
    setValue(value);
  }, [id]);

  const onSearch = (typeData: string): void => {
    setState((prevState) => ({
      ...prevState,
      focusSearch: typeData !== 'search',
    }));
  };

  const getValue = (notValidate?: boolean): string | number => {
    if (!inputRef?.current?.value) return null;

    if (!notValidate && inputRef?.current?.validate) {
      inputRef.current.validate();
    }

    return inputRef.current.value;
  };

  const emptyValue = (noValidate?): void => {
    setValue('', noValidate);
    if (onEmptyValue) {
      onEmptyValue();
    }
    if (toggleSearch) toggleSearch();
  };

  const showPassword = (): void => {
    setState((prevData) => ({ ...prevData, showed: !state.showed }));
  };

  const validationHandle = (res: { OK: boolean; errorMessage: string }): void => {
    const nameInput = id || label || name;
    setErrorsFormValues({ ...errorForm, [nameInput]: res.OK });

    setState((prevState) => ({
      ...prevState,
      valid: res.OK,
      internalMessage: res.errorMessage,
    }));
  };

  useEffect(() => {
    const hasRequiredInValidationRules = validationRules?.some((f) => f.name === 'isRequired');

    if (state.valid && !propsInput?.value && hasRequiredInValidationRules && !errorForm) {
      validationHandle({ OK: false, errorMessage: 'Este campo es obligatorio' });
    }
  }, [errorForm]);

  return (
    <div
      className={`field ${classes || ''}${['checkbox', 'agree'].includes(type) ? ' checkbox' : ''}${
        littleCheck ? ' littleCheck' : ''
      }`}
    >
      <InputField
        id={id}
        name={name}
        onSearch={onSearch}
        propsInput={propsInput}
        selectClass={selectClass}
        type={type}
        validationHandle={validationHandle}
        validationRules={validationRules}
        defaultValue={defaultValue}
        ref={inputRef}
        showed={state.showed}
        validateOnBlur={validateOnBlur}
        placeholder={!!placeHolder && placeHolder}
      >
        {children}
      </InputField>

      {type === 'agree' ? (
        <label htmlFor={id}>
          {`He leído y acepto las `}
          <Link blank href="/privacidad" rel="nofollow" aria="privacidad">
            políticas de privacidad
          </Link>
          {` y las `}
          <Link blank href="/condiciones" rel="nofollow">
            condiciones de servicio
          </Link>
          {` de Axón.`}
        </label>
      ) : (
        label && <label htmlFor={id}>{label}</label>
      )}

      <IconField
        emptyValue={emptyValue}
        expandSearch={expandSearch}
        getValue={getValue}
        showPassword={showPassword}
        toggleSearch={toggleSearch}
        type={type}
        littleCheck={littleCheck}
        showed={state.showed}
      />

      {customText || null}

      {mandatory ? <Icon className="asterisk" /> : null}

      {state.internalMessage ? (
        <p className={`message ${state.valid ? '' : 'error'}`}>{state.internalMessage}</p>
      ) : null}
    </div>
  );
};

export default Field;
