import React, { useEffect, useState } from 'react';

interface InputProps {
  checked?: boolean;
  defaultValue?;
  value?;
  showed?: boolean;
  type?: string;
  name?: string;
  accept?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  onClick?: (data) => void;
  onFocus?: (data) => void;
  maxLength?: number;
  multiple?: boolean;
  style?: React.CSSProperties;
  onKeyUp?: (value) => void;
  onSearch?: (value) => void;
  onValidation?: (value) => void;
  onChange?: (value) => void;
  validationRules?;
  trim?: boolean;
  validateOnBlur?: boolean;
  onBlur?: (value) => void;
  inputId?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      checked,
      defaultValue,
      showed,
      type,
      name,
      accept,
      placeholder,
      className,
      value,
      disabled,
      readonly,
      onClick,
      onFocus,
      maxLength,
      multiple,
      style,
      onKeyUp,
      onSearch,
      validationRules,
      onValidation,
      trim,
      validateOnBlur,
      onChange,
      onBlur,
      inputId,
    }: InputProps,
    ref,
  ): JSX.Element => {
    const [state, setState] = useState({
      checked,
      value: defaultValue,
      showed,
    });

    useEffect(() => {
      if (type === 'checkbox' && name !== 'agree') {
        setState((prevState) => ({ ...prevState, checked, showed }));
      }

      setState((prevState) => ({ ...prevState, showed }));
    }, [showed, checked]);

    const onKeyUpFn = (e): void => {
      if (onKeyUp) {
        onKeyUp(e);
      }

      if (value !== '') {
        if (onSearch) {
          onSearch('close');
        }
        return;
      }

      if (onSearch) {
        onSearch('search');
      }
    };

    const isValid = (e): void => {
      const res = {
        OK: true,
        errorMessage: '',
      };

      const rules = validationRules;
      if (!rules) {
        if (onValidation) {
          onValidation(res);
        }
        return;
      }
      let valueData: string | boolean;

      if (type === 'checkbox') valueData = e.nativeEvent.target.checked;
      else {
        valueData = e.nativeEvent.target.value;
      }

      if (trim) {
        valueData = value?.trim();
      }

      for (let i = 0, l = rules.length; i < l; i += 1) {
        const rule = rules[i];
        if (rule && typeof rule === 'function') {
          const validation = rule(valueData);
          if (!validation.OK) {
            res.OK = false;
            res.errorMessage = validation.error;
          }
        }
      }

      if (onValidation) {
        onValidation(res);
      }
    };

    const onBlurFn = (e): void => {
      const evt = e.nativeEvent;
      if (
        type === 'checkbox' ||
        (validateOnBlur && (evt.type === 'blur' || evt.type === 'focusout'))
      ) {
        isValid(e);
      }

      if (onBlur) {
        onBlur(e);
      }
    };

    const onChangeFn = (e): void => {
      const evt = e.nativeEvent;
      if (!validateOnBlur && evt.type === 'input') {
        isValid(e);
      }

      if (onChange) {
        onChange(e?.target?.value);
      }

      setState((prevState) => ({ ...prevState, checked: !state.checked }));
      if (type === 'checkbox') {
        onBlurFn(e);
      }
    };

    const valueProps: Record<string, unknown> = {
      value,
    };

    if (inputId === 'searchBox') {
      valueProps.defaultValue = defaultValue;
    }

    return (
      <input
        id={inputId}
        name={name}
        accept={accept}
        ref={ref}
        placeholder={placeholder || ''}
        className={`input ${className || ''}`}
        disabled={disabled}
        readOnly={readonly}
        onClick={onClick}
        onChange={onChangeFn}
        onKeyUp={onKeyUpFn}
        onBlur={onBlurFn}
        onFocus={onFocus}
        type={type === 'password' && state.showed ? 'text' : type}
        maxLength={maxLength}
        checked={state.checked}
        style={style}
        multiple={multiple}
        {...valueProps}
      />
    );
  },
);

export default Input;
