import React from 'react';

import Icon from '../../Icon';

interface IconFieldProps {
  type: string;
  expandSearch: () => void;
  getValue: (notValidate?: boolean) => string | number;
  emptyValue: (noValidate?) => void;
  toggleSearch: () => void;
  showPassword: () => void;
  showed?: boolean;
  littleCheck?: boolean;
}

const IconField: React.FC<IconFieldProps> = ({
  type,
  expandSearch,
  getValue,
  emptyValue,
  toggleSearch,
  showPassword,
  showed,
  littleCheck,
}) => {
  switch (type) {
    case 'search':
      return (
        <div>
          <Icon onClick={expandSearch} className="search" nameAriaLabel="searchIconMobile" />
          <Icon
            onClick={getValue() ? emptyValue : toggleSearch}
            className="close pink"
            nameAriaLabel="closePinkIcon"
          />
        </div>
      );

    case 'password':
      return (
        <Icon
          onClick={showPassword}
          className={showed ? 'open' : 'eye-closed'}
          nameAriaLabel="openCloseEyeIcon"
        />
      );

    case 'checkbox':
      return (
        <Icon className={littleCheck ? 'check' : 'bullet'} nameAriaLabel="checkBoxBulletIcon" />
      );

    case 'agree':
      return <Icon className={littleCheck ? 'check' : 'bullet'} nameAriaLabel="agreeBulletIcon" />;

    default:
      return null;
  }
};

export default IconField;
