/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Icon from '../Icon';

interface SelectProps {
  initialValue?;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  tabIndex?: number;
  className?: string;
  onChange?: (value) => void;
  onValidation?: (res: { OK: boolean; errorMessage: string }) => void;
}

interface SelectState {
  active: number;
  text: string;
  value;
  folded: boolean;
}

const Select = ({
  initialValue,
  children,
  tabIndex,
  className,
  onChange,
  onValidation,
}: SelectProps): JSX.Element => {
  const [state, setState] = useState<SelectState>({
    active: 0,
    text: '',
    value: initialValue,
    folded: true,
  });

  useEffect(() => {
    let found = false;
    let firstChildText = '';

    React.Children.forEach(children as React.ReactElement, (child: React.ReactElement, i) => {
      if (i === 0) {
        firstChildText = child.props.children;
      }
      if (child.props.value === initialValue) {
        setState((prevState) => ({
          ...prevState,
          text: child.props.children,
          active: i,
        }));
        found = true;
      }
    });

    if (!found) setState((prevState) => ({ ...prevState, text: firstChildText }));
  }, []);

  const toggleOptions = (): void => {
    setState((prevState) => ({ ...prevState, folded: !state.folded }));
  };

  const selectOption = (i: number, value?, text?: string): void => {
    setState({ active: i, value, text, folded: true });
    if (value !== -1 && onValidation) onValidation({ OK: true, errorMessage: '' });
    if (onChange) {
      onChange(value);
    }
    toggleOptions();
  };

  const buildOptions = (): React.ReactElement[] => {
    return React.Children.map(
      children as React.ReactElement | React.ReactElement[],
      (child: React.ReactElement, i) => {
        return React.cloneElement(child, {
          className: child.props.className + (i === state.active ? ' active' : ''),
          onClick: selectOption.bind(undefined, i),
        });
      },
    );
  };

  const onBlur = (): void => {
    setState((prevState) => ({ ...prevState, folded: true }));
  };

  return (
    <div
      tabIndex={tabIndex || 99}
      onBlur={onBlur}
      className={`select${className ? ` ${className}` : ''}${state.folded ? ' folded' : ''}`}
    >
      <div className="placeholder" onClick={toggleOptions}>
        <p>{state.text}</p>
        <Icon className="arrow-select" />
      </div>
      <div className="optionHolder">{buildOptions()}</div>
    </div>
  );
};

export default Select;
