import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import errorsForm from '../graphql/reactiveVars/formValues';

interface FormValuesReturn {
  setErrorsFormValues: (hasError: Record<string, boolean>) => void;
  getErrorsFormValues: () => Record<string, boolean>;
  errorForm: Record<string, boolean>;
  isValidate: (value: boolean) => boolean;
}

export default function useFormValues(): FormValuesReturn {
  const errorFormRV = useReactiveVar(errorsForm);

  const getErrorsFormValues = (): Record<string, boolean> => {
    return errorFormRV;
  };

  const setErrorsFormValues = (hasError: Record<string, boolean>): void => {
    errorsForm(hasError);
  };

  useEffect(() => {
    if (errorFormRV && Object.keys(errorFormRV).length) setErrorsFormValues({});
  }, []);

  const isValidate = (values: boolean): boolean => {
    if (!values) {
      setErrorsFormValues(null);
    }

    const existError = errorFormRV ? Object.values(errorFormRV).some((err) => !err) : true;

    return !!values && !existError;
  };

  return {
    setErrorsFormValues,
    getErrorsFormValues,
    errorForm: errorFormRV,
    isValidate,
  };
}
