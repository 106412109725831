import React, { forwardRef } from 'react';

import Button from '../Button';
import Select from '../Select';
import Textarea from '../Textarea';
import { ValidationError } from '../../../../models/error.model';

import Input from '../Input';

interface InputProps {
  onKeyUp?: (e?, valueData?) => void;
  onFocus?: (e?, valueData?) => void;
  value?: string | number | boolean;
  placeholder?: string;
  checked?: boolean;
  disabled?: boolean;
  accept?: string;
  id?: string | number;
  name?: string;
  validationRules?;
  validateOnBlur?: boolean;
  propKey?: string;
  onValidation?: (data) => void;
  trim?: boolean;
  onChange?: (data) => void;
  initialValue?;
  onBlur?: (data) => void;
  onClick?: () => void;
}

interface InputFieldProps {
  type: string;
  id: string;
  name: string;
  propsInput: InputProps;
  defaultValue?: string | number | boolean;
  validationHandle: (res: { OK: boolean; errorMessage: string }) => void;
  onSearch: (typeData: string) => void;
  showed?: boolean;
  children: JSX.Element | React.ReactNode | JSX.Element[];
  selectClass: string;
  validationRules: ((data, data2?) => ValidationError)[];
  validateOnBlur?: boolean;
  placeholder?: string;
}

const InputField = forwardRef(
  (
    {
      type,
      id,
      name,
      propsInput,
      defaultValue,
      validationHandle,
      onSearch,
      showed,
      children,
      selectClass,
      validationRules,
      validateOnBlur,
      placeholder,
    }: InputFieldProps,
    inputRef: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const { value, disabled, accept, checked } = propsInput || {};
    const propsToInput = { ...propsInput };

    if (id) {
      propsToInput.id = id;
      propsToInput.name = id;
    }

    if (validationRules) {
      propsToInput.validationRules = validationRules;
      propsToInput.validateOnBlur = validateOnBlur;
      propsToInput.onValidation = validationHandle;
    }

    if (name) {
      propsToInput.name = name;
    }

    switch (type) {
      case 'text':
        return (
          <Input
            id="text"
            inputId={id || 'text'}
            name={name}
            {...propsToInput}
            ref={inputRef}
            type="text"
            defaultValue={defaultValue}
          />
        );
      case 'email': {
        return (
          <Input
            inputId={id || 'email'}
            id="email"
            {...propsToInput}
            placeholder={placeholder}
            ref={inputRef}
            type="email"
            defaultValue={defaultValue || null}
          />
        );
      }
      case 'promo':
        return (
          <Input
            inputId={id || 'promo'}
            id="promo"
            {...propsToInput}
            type="text"
            defaultValue={defaultValue}
          />
        );
      case 'search':
        propsToInput.propKey = 'searchBox';
        return (
          <Input
            id="search"
            inputId={id || 'search'}
            disabled={disabled}
            onValidation={validationHandle}
            {...propsToInput}
            ref={inputRef}
            type="text"
            defaultValue={defaultValue || value}
            onSearch={onSearch}
          />
        );
      case 'file':
        return <Input inputId={id || 'file'} {...propsToInput} type="file" accept={accept} />;
      case 'password':
        return (
          <Input
            inputId={id || 'password'}
            id="password"
            showed={showed}
            defaultValue={defaultValue}
            {...propsToInput}
            type="password"
          />
        );
      case 'checkbox':
        return (
          <Input inputId={id || 'checkbox'} {...propsToInput} type="checkbox" checked={checked} />
        );
      case 'agree':
        return (
          <Input
            inputId={id || 'agree'}
            {...propsToInput}
            type="checkbox"
            name="agree"
            checked={checked}
          />
        );
      case 'number':
        return <Input inputId={id || 'number'} {...propsToInput} type="number" />;
      case 'button':
        return (
          <Button
            id={id || 'button'}
            {...propsToInput}
            value={propsToInput.value as string | number}
            type="button"
          >
            {children}
          </Button>
        );
      case 'submit':
        return (
          <Input inputId={id || 'submit'} {...propsToInput} disabled={disabled} type="submit" />
        );
      case 'textarea':
        return (
          <Textarea
            id={id || 'textarea'}
            {...propsToInput}
            value={propsToInput.value as string | number}
            placeholder=" "
          />
        );
      case 'select':
        return (
          <Select id={id || 'select'} {...propsToInput} className={selectClass}>
            {children}
          </Select>
        );
      default:
        return <Input inputId={id} {...propsToInput} />;
    }
  },
);

export default InputField;
