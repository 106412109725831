import React from 'react';

interface TextAreaProps {
  value?: string | number;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

const Textarea = ({
  value,
  placeholder,
  className,
  disabled,
  required,
  ...props
}: TextAreaProps): JSX.Element => {
  return (
    <textarea
      placeholder={placeholder}
      className={`textarea ${className || ''}`}
      required={required}
      disabled={disabled}
      {...props}
    >
      {value}
    </textarea>
  );
};

export default Textarea;
